#astro {
  -webkit-animation: float 5s infinite;
  -moz-animation:    float 5s infinite;
  -o-animation:      float 5s infinite;
  animation:         float 5s infinite;
}
/*
@keyframes float {
  0% {
    x:0;
    y:83;
    transform:scale(1)
  }
  50% {
    x:20;
    y:40;
    transform:scale(1.1)
  }
  100% {
    x:0;
    y:83;
    transform:scale(1)
  }
}*/


@keyframes float {
  0% {
    transform: translate(20px,-40px) scale(.9);
  }

  50% {
    transform: translate(50px,0px) scale(1);
  }
  100% {
    transform: translate(20px,-40px) scale(.9);
  }
}
