#remember {
	position: relative;
	background: rgba(0, 170, 187,.5);
	border-radius: 15px;;
  width:150px;
  margin-left: 90px;
  text-align:center;
	padding:1px;
}

#remember:after {
	content: '';
	position: absolute;
	bottom: 0;
	left: 55%;
	width: 0;
	height: 0;
	border: 20px solid transparent;
	border-top-color: rgba(0, 170, 187,.5);;
	border-bottom: 0;
	border-right: 0;
	margin-left: -70px;
	margin-bottom: -20px;
}

#bottomBox {


}

#wizsvg {
  height:150px;

}
