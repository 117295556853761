#cactus {

}

#leftBrow {
  animation: leftBrow 2s infinite;
}

@keyframes leftBrow {
  0% {
    transform:translateY(0px);
  }
  50% {
    transform:translateY(-2px);
  }
  80% {
    transform:translateY(-3px);
  }
  100% {
    transform:translateY(0px);
  }
}

#rightBrow {
  animation: rightBrow 2s infinite;
}

@keyframes rightBrow {
  0% {
    transform:translateY(0px);
  }
  20% {
    transform:translateY(0px);
  }
  50% {
    transform:translateY(-1px);
  }
  80% {
    transform:translateY(-2px);
  }
  100% {
    transform:translateY(0px);
  }
}


#leftArm {
  animation: leftArm 1s infinite;
}

@keyframes leftArm {
  0% {
    transform:translateY(0px);
  }
  50% {
    transform:translateY(-5px);
  }
  100% {
    transform:translateY(0px);
  }
}



#rightArm {
  animation: rightArm 1s infinite;
}

@keyframes rightArm {
  0% {
    transform:translateY(0px);
  }
  50% {
    transform:translateY(-5px);
  }
  100% {
    transform:translateY(0px);
  }
}
